<template>
  <div class="content">
    <b-alert
      variant="success"
      show
    >
      <p>Thank you for enrolling!</p>
    </b-alert>

    <div class="video-container-wrapper">
      <div class="video-container">
        <iframe
          :src="version == 'spanish' ? 'https://player.vimeo.com/video/922730330' : 'https://player.vimeo.com/video/723561127'"
          frameborder="0"
          webkitAllowFullScreen
          mozallowfullscreen
          allowFullScreen
        />
      </div>

      <b-button
        variant="primary"
        @click="$router.push({ name: 'main' })"
      >
        Get Started
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Welcome',
  props: {
    version: {
      type: String,
      required: false,
      default: 'english',
    },
  },
};
</script>

<style lang="scss" scoped>
  .video-container-wrapper {
    max-width: 500px;
    margin: 0 auto;
  }
</style>
